.full_select_car {
    background: var(--bg);
    min-height: 462px;
    display: grid;
    grid-template-columns: 436px 1fr;
    align-items: center;
    justify-items: center;
    margin: 77px auto;
    margin-bottom: 0;
}

#book_car {
    width: 100%;
    overflow-x: hidden;
}

#book_car .book_car {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
}


.offers {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 13px;
}

.offers .offer {
    display: flex;
    flex-direction: column;
    padding: 39px;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    color: var(--bg_2);
    cursor: pointer;
    flex: 1;
    border-radius: 40px;
    border: 1px solid var(--bg_2);
    gap: 27px;
}

.offers .offer:hover {
    background: var(--bg);
    color: var(--ff);
}


.offers p {
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 10px;
}

.offers h3 {
    font-size: 25px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    white-space: nowrap;
}

.offers .offer .offer_t_2 h3 {
    font-size: 35px;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 8px;
}

.offers .offer .offer_t_3 p {
    font-size: 25px;
    line-height: 30px;
}

.offers .offer .offer_t_4 {
    display: flex;
    align-items: flex-end;
    gap: 5px;
}

.offers .offer .offer_t_4 h2 {
    font-size: 45px;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: center;
}

.offers .offer .offer_t_4 span {
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
}

.full_select_car .title {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    position: relative;
}

.full_select_car .title h2 {
    font-size: 47px;
    font-weight: 700;
    line-height: 57px;
    letter-spacing: 0em;
    color: var(--bg_2);
    position: relative;
    z-index: 1;
    text-transform: uppercase;
}

.full_select_car .title span {
    position: absolute;
    background: var(--ff);
    top: 50%;
    left: 0;
    width: 109px;
    height: 109px;
    border-radius: 50%;
    transform: translate(30%, -45%);
}


form.select_car_type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: 32px;
    padding: 77px 40px;
}


form.select_car_type .top,
form.select_car_type .bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 26px;
}

form.select_car_type .select {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    gap: 16px;
}

form.select_car_type .select label {
    font-size: 25px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    color: var(--bg_2);
}

form.select_car_type .select select,
form.select_car_type .select input {
    width: 100%;
    border-radius: 5rem;
    border: 1px solid var(--bg);
    appearance: none;
    height: 65.18px;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    cursor: pointer;
}

form.select_car_type .select select::placeholder {
    opacity: 30%;
}


form.select_car_type .select_icon {
    width: 100%;
    position: relative;
}

svg.io_ios_arrow_down {
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translateY(-50%);
    font-size: 20px;
}

form.select_car_type .bottom input.submit {
    width: 288.35px;
    border-radius: 5rem;
    background: var(--bg_2);
    font-size: 16px;
    margin-top: auto;
    color: var(--ff);
    font-weight: 400;
    line-height: 19px;
    height: 65.46px;
    text-transform: uppercase;
    cursor: pointer;
}

form.select_car_type .bottom input.submit:hover {
    opacity: 0.7;
}

select option:nth-child(1) {
    display: none;
}

select option {
    font-size: 20px;
}

input[type="date"] {
    position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    position: absolute;
    right: 5%;
    opacity: 0;
    width: 100%;
    height: 100%;
}

.select {
    transition: all 700ms ease-in-out;
}

.submit {
    transition: all 1s ease-in-out;
}

.hide_selected {
    transform: scaleY(0);
}

.show_selected {
    transform: scaleY(1);
}

form.select_car_type .top div.selectgcc {
    width: 100%;
    border-radius: 5rem;
    background: var(--ff);
    appearance: none;
    height: 65.18px;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}


@media (max-width: 1200px) {
    form.select_car_type .bottom input.submit {
        width: 210px;
    }
}

@media (max-width: 1100px) {
    #book_car {
        height: auto;
        padding: 0 35px;
    }

    .title_div h2 {
        font-size: 40px;
        line-height: 50px;
    }

    form.select_car_type {
        gap: 25px;
    }

    form.select_car_type .top,
    form.select_car_type .bottom {
        gap: 20px;
    }

    form.select_car_type .select {
        gap: 14px;
    }

    form.select_car_type .select label {
        font-size: 20px;
        line-height: 25px;
    }

    form.select_car_type .select select,
    form.select_car_type .select input {
        font-size: 10px;
        height: 46px;
    }

    svg.io_ios_arrow_down {
        font-size: 16px;
    }

    form.select_car_type .bottom input.submit {
        width: 120px;
        font-size: 15px;
        line-height: 17px;
        height: 46px;
    }

    .offers .offer {
        gap: 25px;
        padding: 30px;
    }

    .offers p {
        font-size: 13.5px;
        line-height: 16.2px;
        margin-bottom: 9px;
    }

    .offers h3 {
        font-size: 22.5px;
        line-height: 27px;
    }

    .offers .offer .offer_t_2 h3 {
        font-size: 31.5px;
        line-height: 37.8px;
        margin-top: 7.2px;
    }

    .offers .offer .offer_t_3 p {
        font-size: 22.5px;
        line-height: 27px;
    }

    .offers .offer .offer_t_4 {
        gap: 4.5px;
    }

    .offers .offer .offer_t_4 h2 {
        font-size: 40.5px;
        line-height: 48.6px;
    }

    .offers .offer .offer_t_4 span {
        font-size: 13.5px;
        line-height: 16.2px;
    }

    .full_select_car .title h2 {
        font-size: 40px;
        line-height: 50px;
    }

    .full_select_car {
        grid-template-columns: 380px 1fr;
    }

    form.select_car_type .top div.selectgcc {
        font-size: 10px;
        height: 46px;
    }
}

@media (max-width: 900px) {
    #book_car {
        height: auto;
        padding: 0 31.5px;
    }

    .title_div h2 {
        font-size: 36px;
        line-height: 45px;
    }

    form.select_car_type {
        gap: 22.5px;
    }

    form.select_car_type .top,
    form.select_car_type .bottom {
        gap: 18px;
    }

    form.select_car_type .select {
        gap: 12.6px;
    }

    form.select_car_type .select label {
        font-size: 18px;
        line-height: 22.5px;
    }

    form.select_car_type .select select,
    form.select_car_type .select input {
        font-size: 10px;
        height: 45px;
    }

    svg.io_ios_arrow_down {
        font-size: 10px;
    }

    form.select_car_type .bottom input.submit {
        width: 108px;
        font-size: 13.5px;
        line-height: 15.3px;
        height: 45px;
    }

    .offers .offer {
        gap: 20px;
        padding: 31.5px;
    }

    .offers p {
        font-size: 12.15px;
        line-height: 14.58px;
        margin-bottom: 8.1px;
    }

    .offers h3 {
        font-size: 20.25px;
        line-height: 24.3px;
    }

    .offers .offer .offer_t_2 h3 {
        font-size: 28.35px;
        line-height: 34.02px;
        margin-top: 6.48px;
    }

    .offers .offer .offer_t_3 p {
        font-size: 20.25px;
        line-height: 24.3px;
    }

    .offers .offer .offer_t_4 {
        gap: 4.05px;
    }

    .offers .offer .offer_t_4 h2 {
        font-size: 36.45px;
        line-height: 43.74px;
    }

    .offers .offer .offer_t_4 span {
        font-size: 12.15px;
        line-height: 14.58px;
    }

    .full_select_car .title span {
        width: 100px;
        height: 100px;
    }

    .full_select_car .title h2 {
        font-size: 30px;
        line-height: 40px;
    }

    .full_select_car {
        grid-template-columns: 300px 1fr;
    }
}

@media (max-width: 750px) {
    #book_car {
        height: auto;
        padding: 0 28.35px;
        margin: 30px auto;
    }

    .title_div h2 {
        font-size: 32.4px;
        line-height: 40.5px;
    }

    form.select_car_type {
        gap: 20px;
    }

    form.select_car_type .top,
    form.select_car_type .bottom {
        gap: 16.2px;
    }

    form.select_car_type .select {
        gap: 11.34px;
    }

    form.select_car_type .select label {
        font-size: 16.2px;
        line-height: 20.25px;
    }

    form.select_car_type .select select,
    form.select_car_type .select input {
        font-size: 12.96px;
        height: 40.5px;
    }

    svg.io_ios_arrow_down {
        font-size: 12.96px;
    }

    form.select_car_type .bottom input.submit {
        width: 97.2px;
        font-size: 12.15px;
        line-height: 13.77px;
        height: 40.5px;
    }

    .offers .offer {
        gap: 10px;
        padding: 26.775px;
    }


    .offers p {
        font-size: 10px;
        line-height: 12px;
        margin-bottom: 6px;
    }

    .offers h3 {
        font-size: 15px;
        line-height: 15px;
    }

    .offers .offer .offer_t_2 h3 {
        font-size: 20px;
        line-height: 22px;
        margin-top: 5px;
    }

    .offers .offer .offer_t_3 p {
        font-size: 15px;
        line-height: 17px;
    }

    .offers .offer .offer_t_4 {
        gap: 3px;
    }

    .offers .offer .offer_t_4 h2 {
        font-size: 25px;
        line-height: 30px;
    }

    .offers .offer .offer_t_4 span {
        font-size: 10px;
        line-height: 10px;
    }

    .offers {
        flex-wrap: wrap;
    }

    .full_select_car .title span {
        width: 80px;
        height: 80px;
    }

    .full_select_car .title h2 {
        font-size: 30px;
        line-height: 30px;
    }

    .full_select_car {
        grid-template-columns: 1fr;
        padding: 70px 0;
    }

    .full_select_car .title {
        width: 280px;
    }

    form.select_car_type {
        gap: 22px;
        padding: 40px 20px;
    }

    form.select_car_type .top div.selectgcc {
        font-size: 12.96px;
        height: 40.5px;
    }
}


@media (max-width: 600px) {
    #book_car {
        height: auto;
        padding: 0 25px;
        margin: 30px auto;
    }

    .title_div h2 {
        font-size: 29.16px;
        line-height: 36.45px;
    }

    form.select_car_type {
        gap: 18.225px;
    }

    form.select_car_type .top,
    form.select_car_type .bottom {
        gap: 14.58px;
    }

    form.select_car_type .select {
        gap: 10.206px;
    }

    form.select_car_type .select label {
        font-size: 14.58px;
        line-height: 18.225px;
    }

    form.select_car_type .select select,
    form.select_car_type .select input {
        font-size: 11.664px;
        height: 36.45px;
    }

    svg.io_ios_arrow_down {
        font-size: 11.664px;
    }

    form.select_car_type .bottom input.submit {
        width: 87.48px;
        font-size: 10.935px;
        line-height: 12.393px;
        height: 36.45px;
    }

    .offers .offer {
        gap: 8.5px;
        padding: 22.75875px;
    }

    .offers p {
        line-height: 10.2px;
        margin-bottom: 5.1px;
    }

    .offers h3 {
        font-size: 12.75px;
        line-height: 12.75px;
    }

    .offers .offer .offer_t_2 h3 {
        font-size: 17px;
        line-height: 18.7px;
        margin-top: 4.25px;
    }

    .offers .offer .offer_t_3 p {
        font-size: 12.75px;
        line-height: 14.45px;
    }

    .offers .offer .offer_t_4 {
        gap: 2.55px;
    }

    .offers .offer .offer_t_4 h2 {
        font-size: 21.25px;
        line-height: 25.5px;
    }

    .offers .offer .offer_t_4 span {
        line-height: 8.5px;
    }

    form.select_car_type .top div.selectgcc {
        font-size: 11.664px;
        height: 36.45px;
    }
}




@media (max-width: 430px) {
    #book_car {
        height: auto;
        padding: 0 30px;
        margin: 30px auto;
    }

    form.select_car_type .top,
    form.select_car_type .bottom {
        flex-direction: column;
        gap: 15px;
    }

    form.select_car_type .select {
        width: 100%;
    }

    form.select_car_type {
        gap: 85px;
    }



    form.select_car_type .bottom input.submit {
        width: 137.01px;
        height: 53.46px;
        margin: auto;
    }

    form.select_car_type .select select,
    form.select_car_type .select input {
        height: 63px;
    }

    .offers .offer {
        gap: 7.225px;
        padding: 19.3459375px;
    }

    .offers p {
        line-height: 8.67px;
        margin-bottom: 4.335px;
    }

    .offers h3 {
        font-size: 10.8375px;
        line-height: 10.8375px;
    }

    .offers .offer .offer_t_2 h3 {
        font-size: 14.45px;
        line-height: 15.9455px;
        margin-top: 3.6125px;
    }

    .offers .offer .offer_t_3 p {
        font-size: 10.8375px;
        line-height: 12.30075px;
    }

    .offers .offer .offer_t_4 {
        gap: 2.1675px;
    }

    .offers .offer .offer_t_4 h2 {
        font-size: 18.0625px;
        line-height: 21.675px;
    }

    .offers .offer .offer_t_4 span {
        line-height: 7.225px;
    }

    form.select_car_type .top div.selectgcc {
        min-height: 63px;
    }
}

@media (min-width: 320px) and (max-width: 400px) {
    #book_car {
        height: auto;
        padding: 0 25.5px;
    }

    form.select_car_type .top,
    form.select_car_type .bottom {
        flex-direction: column;
        gap: 12.75px;
    }

    form.select_car_type .select {
        width: 100%;
    }

    form.select_car_type {
        gap: 72.25px;
    }


    form.select_car_type .bottom input.submit {
        width: 116.46px;
        height: 45.44px;
        margin: auto;
    }

    form.select_car_type .select select,
    form.select_car_type .select input {
        height: 53.55px;
    }
}

/* direction_rtl */

.direction_rtl .full_select_car .title {
    justify-content: center;
}

.direction_rtl .full_select_car .title span {
    left: auto;
    right: 0;
    transform: translate(-100%, -45%);
}