#about {
    width: 100%;
    height: 720px;
    background: url("../../../img/about_1.webp") no-repeat, url("../../../img/about.webp") no-repeat;
    background-size: cover;
    background-position: center;
    padding: 0 41px;
    position: relative;
    z-index: -1;
    overflow: hidden;
}

#about .about {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    overflow: hidden;
}

#about .about h2.title {
    transform: translateX(-20%);
}

#about .about .start_engine {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 50px;
    width: 692px;
}

#about .about .start_engine .start_engine_count {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 49px;
}

#about .about .title.engine {
    transform: translateX(-5%);
}

#about .about .start_engine h3 {
    color: var(--ff);
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-transform: uppercase;
    margin-bottom: 14px;
}


#about .about .start_engine p {
    color: var(--ff);
    font-size: 22px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
}

#about .about .start_engine .start_engine_count .count {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#about .about .start_engine .start_engine_count .count b {
    font-size: 40px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    color: var(--bg);
}

#about .about .start_engine .start_engine_count .count p {
    font-size: 22px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    color: var(--ff);
    margin-top: 10px;
    text-transform: uppercase;
}

#about .span span {
    display: block;
    width: 158px;
    height: 47.5px;
    background: var(--ff);
}

#about .span {
    position: absolute;
    top: 100%;
    left: 87.5px;
    transform: rotate(90deg) translateX(-100%);
}

#about .span span:nth-child(2) {
    margin: 70px 0;
}

#about .about .start_engine h3 span {
    color: var(--bg);
}

@media (max-width: 1100px) {
    #about {
        height: 600px;
    }

    .title_div h2 {
        font-size: 35px;
        line-height: 40px;
    }

    .title_div h2::before {
        width: 90px;
        height: 90px;
    }

    #about .about .start_engine {
        margin-bottom: 0;
        width: 550px;
    }

    #about .about .start_engine h3 {
        font-size: 25px;
        font-weight: 600;
        line-height: 30px;
        margin-bottom: 13px;
    }

    #about .about .start_engine p {
        font-size: 18px;
        line-height: 25px;
    }

    #about .about .start_engine .start_engine_count {
        margin-top: 40px;
    }

    #about .about .start_engine .start_engine_count .count b {
        font-size: 25px;
        line-height: 30px;
    }

    #about .about .start_engine .start_engine_count .count p {
        font-size: 18px;
        line-height: 20px;
        margin-top: 8px;
    }

    #about .span span {
        width: 130px;
        height: 40px;
    }

    #about .span span:nth-child(2) {
        margin: 50px 0;
    }
}

@media (max-width: 900px) {
    #about {
        height: 540px;
    }

    .title_div h2 {
        font-size: 31.5px;
        line-height: 36px;
    }

    .title_div h2::before {
        width: 81px;
        height: 81px;
    }

    #about .about .start_engine {
        margin-bottom: 0;
        width: 480px;
    }

    #about .about .start_engine h3 {
        font-size: 22.5px;
        font-weight: 540;
        line-height: 27px;
        margin-bottom: 11.7px;
    }

    #about .about .start_engine p {
        font-size: 16.2px;
        line-height: 22.5px;
    }

    #about .about .start_engine .start_engine_count {
        margin-top: 36px;
    }

    #about .about .start_engine .start_engine_count .count b {
        font-size: 22.5px;
        line-height: 27px;
    }

    #about .about .start_engine .start_engine_count .count p {
        font-size: 16.2px;
        line-height: 18px;
        margin-top: 7.2px;
    }

    #about .span span {
        width: 75px;
        height: 30px;
    }

    #about .span span:nth-child(2) {
        margin: 30.5px 0;
    }
}

@media (max-width: 750px) {
    #about {
        height: 486px;
    }

    .title_div h2 {
        font-size: 28.35px;
        line-height: 32.4px;
    }

    .title_div h2::before {
        width: 72.9px;
        height: 72.9px;
    }

    #about .about .start_engine {
        margin-bottom: 0;
        width: 400.5px;
    }

    #about .about .start_engine h3 {
        font-size: 20.25px;
        font-weight: 486;
        line-height: 24.3px;
        margin-bottom: 10.53px;
    }

    #about .about .start_engine p {
        font-size: 14.58px;
        line-height: 20.25px;
    }

    #about .about .start_engine .start_engine_count {
        margin-top: 32.4px;
    }

    #about .about .start_engine .start_engine_count .count b {
        font-size: 20.25px;
        line-height: 24.3px;
    }

    #about .about .start_engine .start_engine_count .count p {
        font-size: 14.58px;
        line-height: 16.2px;
        margin-top: 6.48px;
    }

    #about .span span {
        width: 65px;
        height: 30px;
    }

    #about .span span:nth-child(2) {
        margin: 35px 0;
    }
}


@media (max-width: 600px) {
    #about {
        height: 437.4px;
    }

    .title_div h2 {
        font-size: 25.515px;
        line-height: 29.16px;
    }

    .title_div h2::before {
        width: 65.61px;
        height: 65.61px;
    }

    #about .about .start_engine {
        margin-bottom: 0;
        width: 360.95px;
    }

    #about .about .start_engine h3 {
        font-size: 18.225px;
        font-weight: 437.4;
        line-height: 21.87px;
        margin-bottom: 9.477px;
    }

    #about .about .start_engine p {
        font-size: 13.134px;
        line-height: 18.225px;
    }

    #about .about .start_engine .start_engine_count {
        margin-top: 29.16px;
    }

    #about .about .start_engine .start_engine_count .count b {
        font-size: 18.225px;
        line-height: 21.87px;
    }

    #about .about .start_engine .start_engine_count .count p {
        font-size: 13.134px;
        line-height: 14.58px;
        margin-top: 5.832px;
    }

    #about .span {
        left: 50%;
    }
}


@media (max-width: 430px) {
    #about {
        height: 869px;
        padding: 0 30px;
        background: url("../../../img/about_phone_1.webp") no-repeat,
            url("../../../img/about_phone.webp") no-repeat;
        background-size: cover;
        background-position: center;
    }

    #about .about .title_div:nth-child(1) {
        margin-top: 74px;
        margin-bottom: 54px;
    }

    #about .about .start_engine {
        align-items: center;
        text-align: center;
        margin-bottom: 0;
    }

    #about .about .start_engine h3 {
        font-size: 24px;
        line-height: 29.05px;
        width: 365px;
    }

    #about .about .start_engine p {
        font-size: 17px;
        line-height: 20.57px;
        color: var(--ff);
        width: 279px;
        margin-bottom: 0;
    }

    #about .about .start_engine .start_engine_count {
        gap: 58px;
        flex-direction: column;
        margin-top: 54px;
        margin-bottom: 87px;
    }

    #about .about .start_engine h3:nth-child(2) {
        margin-bottom: 28px;
    }

}

@media (min-width: 320px) and (max-width: 400px) {
    #about {
        height: 738.65px;
        padding: 0 25.5px;
        background: url("../../../img/about_phone_1.webp") no-repeat,
            url("../../../img/about_phone.webp") no-repeat;
        background-size: cover;
        background-position: center;
    }

    #about .about .title_div:nth-child(1) {
        margin-top: 62.9px;
        margin-bottom: 45.9px;
    }

    #about .about .start_engine {
        align-items: center;
        text-align: center;
        margin-bottom: 0;
    }

    #about .about .start_engine h3 {
        font-size: 20.4px;
        line-height: 24.69px;
        width: 310.25px;
    }

    #about .about .start_engine p {
        font-size: 14.45px;
        line-height: 17.49px;
        color: var(--ff);
        width: 237.15px;
        margin-bottom: 0;
    }

    #about .about .start_engine .start_engine_count {
        gap: 49.3px;
        flex-direction: column;
        margin-top: 45.9px;
        margin-bottom: 73.95px;
    }

    #about .about .start_engine h3:nth-child(2) {
        margin-bottom: 23.8px;
    }
}


/* direction_rtl */
.direction_rtl #about {
    transform: rotateY(180deg);
}

.direction_rtl #about .about {
    transform: rotateY(180deg);
}

.direction_rtl #about .about .title.engine {
    transform: translateX(5%);
}