.package button.terms_book_now {
    padding: 10px 20px;
}

#show_select .show_select .offer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5vh;
}

#show_select .show_select .offer .Package_full_ul {
    margin-top: 10px;
}