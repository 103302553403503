nav {
    position: fixed;
    z-index: 10;
    left: 0;
    right: 0;
    top: 46px;
    height: 70px;
    transition: all 500ms ease-in-out;
    max-width: 1280px;
    margin: auto;
    padding: 0 41px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}


nav.scrolled {
    top: 0;
    background: var(--ff);
}

nav .logo {
    float: left;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 24px;
    color: var(--ff);
}

nav .logo img {
    width: 177px;
    transition: all 1s ease-in-out;
}

nav .links {
    float: right;
    padding: 0;
    margin: 0;
    width: max-content;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

nav .links li {
    cursor: pointer;
}

nav .links a,
nav .links button {
    display: block;
    padding: 1em;
    color: var(--bg_2);
    text-decoration: none;
    position: relative;
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-transform: uppercase;
}

nav .links button {
    background: transparent;
    cursor: pointer;
}

nav .links a:hover,
nav .links button:hover {
    opacity: 0.7;
}

nav a.active {
    color: var(--bg);
}


#nav-toggle {
    position: absolute;
    top: -100vh;
}

nav .auth {
    display: flex;
    align-items: center;
    gap: 11px;
}

nav .icon-burger {
    display: none;
}

nav .icon-burger .line {
    width: 30px;
    height: 5px;
    background: var(--bg_2);
    margin: 5px;
    border-radius: 3px;
    transition: all .5s ease-in-out;
}


nav a.logo.active {
    color: transparent;
}

nav .auth {
    display: flex;
    align-items: center;
    gap: 11px;
}

.auth a.login {
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    color: var(--bg_2);
    text-transform: uppercase;
}

nav .auth a.register {
    background: var(--bg);
    color: var(--ff);
    padding: 10px 15px;
    border-radius: 24px;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-transform: uppercase;
}

.auth a.login:hover,
nav .auth a.register:hover {
    opacity: 0.7;
}

nav a.logo {
    cursor: pointer;
    width: auto;
    height: auto;
    border-radius: inherit;
}

.ChangeLang {
    background: var(--bg_2);
    color: var(--ff);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 5px;
}

.ChangeLang:hover {
    opacity: 0.7;
}

@media (max-width: 996px) {

    nav .links a,
    nav .links button {
        padding: 10px;
        font-size: 16px;
        font-weight: 500;
    }

    nav a.contact {
        border: 2px solid var(--ff);
        padding: 7px 12px;
        font-size: 16px;
        font-weight: 500;
    }

    nav .logo img {
        width: 70px;
    }
}

li.auth.phone {
    display: none;
}

@media screen and (min-width: 768px) and (max-width: 1070px) {
    nav .logo img {
        width: 110px;
    }

    nav .links a,
    nav .links button {
        padding: 14px;
        padding-bottom: 10px;
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
    }

    nav .auth {
        gap: 9px;
    }

    .auth a.login {
        font-size: 10px;
        font-weight: 300;
        line-height: 17px;
    }

    nav .auth a.register {
        padding: 8px 13px;
        font-size: 11px;
        line-height: 14px;
    }
}


@media (max-width: 768px) {
    .auth.computer {
        display: none;
    }

    li.auth.phone {
        display: flex;
    }

    nav {
        flex-direction: row;
    }

    nav .logo {
        float: none;
        width: auto;
        justify-content: center;
    }

    nav .links {
        float: none;
        position: fixed;
        z-index: 9;
        left: 0;
        right: 0;
        top: 70px;
        bottom: 100%;
        width: auto;
        height: auto;
        flex-direction: column;
        justify-content: flex-start;
        gap: 1rem;
        background: var(--ff);
        overflow: hidden;
        transition: all .5s ease-in-out;
    }

    nav .links a,
    nav .links button {
        font-size: 1rem;
        color: var(--bg_2);
        padding: 5px 1em;
        padding-bottom: 10px;
    }


    nav .logo img {
        width: 200px;
    }

    nav a.contact {
        border: 3px solid var(--bg);
        font-size: 12px;
    }

    nav a.active {
        color: var(--bg);
    }

    nav :checked~.links {
        bottom: 0;
        padding-top: 1rem;
    }

    nav .icon-burger {
        display: block;
    }


    nav :checked~.icon-burger .line:nth-child(1) {
        transform: translateY(10px) rotate(225deg);
    }

    nav :checked~.icon-burger .line:nth-child(3) {
        transform: translateY(-10px) rotate(-225deg);
    }

    nav :checked~.icon-burger .line:nth-child(2) {
        opacity: 0;
    }
}



@media (max-width: 430px) {
    nav {
        padding: 0 30px;
    }

    nav .icon-burger .line {
        width: 15px;
        height: 2px;
        background: var(--bg_2);
        margin: 2px 0;
        border-radius: 3px;
        transition: all .5s ease-in-out;
    }

    nav a.logo {
        position: relative;
        top: 72px;
        left: 35%;
        transform: translate(-50%, -50%);
    }

    nav :checked~a.logo img {
        width: 65px;
    }

    nav.scrolled a.logo img {
        width: 120px;
    }

    nav :checked~a.logo {
        position: initial !important;
        transform: translate(0, 0);
    }

    nav.scrolled a.logo {
        position: initial !important;
        transform: translate(0, 0);
    }

    nav :checked~.icon-burger .line:nth-child(1) {
        transform: translateY(2px) rotate(225deg);
        width: 20px;
    }

    nav :checked~.icon-burger .line:nth-child(3) {
        transform: translateY(-5px) rotate(-225deg);
        width: 20px;
    }

    nav :checked~.icon-burger .line:nth-child(2) {
        opacity: 0;
    }
}

@media (min-width: 320px) and (max-width: 400px) {
    nav :checked~.icon-burger .line:nth-child(1) {
        transform: translateY(5px) rotate(225deg);
        width: 20px;
    }

    nav :checked~.icon-burger .line:nth-child(3) {
        transform: translateY(-3px) rotate(-225deg);
        width: 20px;
    }
}