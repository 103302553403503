#footer {
    width: 100%;
    height: 100%;
    padding: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5rem;
    background: #ce0006;
    position: relative;
}

#footer .scroll-to-top {
    position: absolute;
    width: 56px;
    height: 56px;
    top: 0;
    right: 0;
    background: var(--bg_2);
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-100%, -50%);
    border-radius: 500px;
    cursor: pointer;
    z-index: 1;
}

#footer .scroll-to-top:hover {
    opacity: 0.7;
}

#footer .scroll-to-top svg {
    color: var(--ff);
    font-size: 30px;
}

#footer .footer {
    display: flex;
    width: 100%;
    height: 100%;
    margin: auto;
}

#footer .footer .all_footer {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 100px;
}

#footer .footer .all_footer .left {
    width: 147px;
}

#footer .footer .all_footer .center h2 {
    font-size: 25px;
    font-weight: 700;
    line-height: 30.26px;
    letter-spacing: 0em;
    color: var(--bg_2);
    margin-bottom: 9px;
}

#footer .footer .all_footer .center h2 span {
    font-weight: 400;
}

#footer .footer .all_footer .center p {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: var(--ff);
}

#footer .footer .all_footer .right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 30px;
}

#footer .footer .all_footer .right ul.links {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: column;
}

#footer .footer .all_footer .right ul.links a {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    color: var(--bg_2);
    text-transform: uppercase;
    cursor: pointer;
}

#footer .footer .all_footer .right ul.links li:nth-child(1) a.active {
    color: var(--ff);
    font-weight: 700;
}

#footer .footer .all_footer .right ul.links a:hover {
    opacity: 0.7;
}

#footer .footer .all_footer .right a.book_now {
    background: var(--bg_2);
    color: var(--ff);
    width: 124.86px;
    height: 51.41px;
    display: flex;
    border-radius: 5rem;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    letter-spacing: 0em;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

#footer .footer .all_footer .right a.book_now:hover {
    opacity: 0.7;
}

#footer .footer .all_footer .center b,
#footer .footer .all_footer .center a {
    font-size: 15px;
    font-weight: bold;
    line-height: 25px;
    letter-spacing: 0em;
    color: var(--ff);
    display: flex;
    align-items: center;
    gap: 5px;
}

#footer .footer .all_footer .center svg {
    font-size: 20px;
    border-radius: 5px;
}

#footer .footer .all_footer .center {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

#footer .footer .all_footer .center .email_phone {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
}

@media (max-width: 1100px) {
    #footer {
        padding: 60px;
    }

    #footer .footer .all_footer .center h2 {
        font-size: 22.5px;
        line-height: 27.24px;
        margin-bottom: 8.1px;
    }

    #footer .footer .all_footer .center p {
        font-size: 13.5px;
        line-height: 18px;
    }

    #footer .footer .all_footer .center b,
    #footer .footer .all_footer .center a {
        font-size: 13.5px;
        line-height: 22.5px;
    }

    #footer .footer .all_footer .right {
        gap: 27px;
    }

    #footer .footer .all_footer .right a.book_now {
        width: 112.37px;
        height: 46.27px;
        font-size: 14.4px;
        line-height: 17.42px;
    }


    #footer .footer .all_footer .right ul.links a {
        font-size: 14.4px;
        line-height: 17.1px;
    }
}

@media (max-width: 900px) {
    #footer {
        padding: 50px;
    }

    #footer .footer .all_footer {
        flex-direction: column;
        gap: 1rem;
    }

    #footer .footer .all_footer .center {
        width: 100%;
        text-align: center;
    }

    #footer .footer .all_footer .center h2 {
        font-size: 20.25px;
        line-height: 24.52px;
        margin-bottom: 7.29px;
    }

    #footer .footer .all_footer .center p {
        font-size: 12.15px;
        line-height: 16.2px;
    }

    #footer .footer .all_footer .center b,
    #footer .footer .all_footer .center a {
        font-size: 12.15px;
        line-height: 20.25px;
    }

    #footer .footer .all_footer .right {
        gap: 24.3px;
    }

    #footer .footer .all_footer .right a.book_now {
        width: 101.13px;
        height: 41.64px;
        font-size: 12.96px;
        line-height: 15.68px;
    }


    #footer .footer .all_footer .right ul.links a {
        font-size: 12.96px;
        line-height: 15.39px;
    }

}


@media (max-width: 430px) {
    #footer {
        padding: 28px 10px;
    }

    #footer .footer .all_footer {
        align-items: center;
        gap: 10px;
    }

    #footer .footer .all_footer .right {
        display: none;
    }

    #footer .footer .all_footer .left {
        width: 105px;
        transform: none;
    }

    #footer .footer .all_footer .center {
        width: 100%;
        align-items: center;
    }

    #footer .footer .all_footer .center h2 {
        font-size: 25px;
        line-height: 30px;
    }

    #footer .footer .all_footer .center p {
        font-size: 16px;
        line-height: 19.36px;
    }

    #footer .footer .all_footer .center .email_phone {
        flex-direction: column;
        align-items: center;
    }
}