:root {
  --bg: #EE050B;
  --bg_2: #001C54;
  --cl: #F0F9FF;
  --cl_2: #F3FBFF;
  --ff: #fff;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 9px;
}

::-webkit-scrollbar-track {
  background: #b7b7b7;
}

::-webkit-scrollbar-thumb {
  background: var(--bg);
  border-radius: 20px;
}

img,
video {
  width: 100%;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  overflow-x: hidden;
  font-family: 'Inter', sans-serif;
  max-width: 1280px;
  margin: auto;
}

@media (max-width: 430px) {
  body {
    max-width: 430px;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.Toastify__toast-container {
  z-index: 99999999;
}

.title_div {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "col1 col2";
}

.title_div .title {
  width: -moz-fit-content;
  width: fit-content;
  grid-area: col2;
  transform: translateX(-50%);
}

.title_div h2 {
  font-size: 50px;
  font-weight: 700;
  line-height: 60.51px;
  letter-spacing: 0em;
  color: var(--bg_2);
  position: relative;
  text-transform: uppercase;
  margin: 25px 0;
}

.title_div h2::before {
  content: "";
  position: absolute;
  background: var(--bg);
  top: 50%;
  width: 109px;
  height: 109px;
  border-radius: 50%;
  transform: translate(-30%, -50%);
  z-index: -1;
}

.title_div p {
  font-size: 22px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  position: relative;
  color: var(--bg_2);
}

@media (max-width: 430px) {
  .title_div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title_div .title {
    transform: none !important;
  }

  .title_div h2 {
    font-size: 40px;
    line-height: 48.41px;
    white-space: nowrap;
  }

  .title_div h2::before {
    width: 102.97px;
    height: 102.97px;
    transform: translate(-25%, -50%);
  }

  div#root {
    overflow-x: hidden;
  }
}



/* .direction_rtl */
.direction_rtl .title_div .title {
  transform: translateX(50%);
}

.direction_rtl .title_div h2::before {
  transform: translate(30%, -50%);
}