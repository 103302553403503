.package button.terms_book_now {
    padding: 10px 20px;
}

.show_select_button {
    display: flex;
    flex-direction: column;
}

.show_select_button button.terms_book_now {
    font-size: 13px !important;
}

#show_select .show_select .package .Package_full_ul {
    margin-top: 10px;
}