#show_select {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    animation: show_select 1s ease-in-out;
}

.show_select_terms {
    z-index: 9999999 !important;
}

.show_select_terms .show_select {
    justify-content: center;
}

@keyframes show_select {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

#show_select img.toyota_land_cruiser_2023 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

#show_select .show_select {
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    padding: 35px 0;
}


#show_select .show_select h2 {
    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    color: var(--ff);
    position: relative;
    text-transform: uppercase;
    margin: 17px 0;
}

.selected_data_all {
    display: flex;
    gap: 10px;
}


.selected_data_all .selected_data b {
    font-size: 25px;
    color: var(--ff);
}

.selected_data_all .selected_data p {
    font-size: 70px;
    font-weight: 700;
    color: var(--ff);
    line-height: 55px;
    margin-top: 10px;
    border: 1px solid var(--ff);
    border-radius: 15px;
    padding: 10px;
    display: flex;
    align-items: flex-end;
}

.selected_data_all .selected_data p span {
    font-size: 20px;
    line-height: 20px;
}

.selected_data.data_1 p {
    background: var(--bg);
}

.selected_data.data_2 p,
.selected_data.data_3 p {
    background: var(--ff);
    color: var(--bg_2);
}

#show_select .show_select .Package_full_ul {
    display: flex;
    flex-direction: column;
    max-width: 500px;
}

#show_select .show_select .Package_full_ul ul {
    color: var(--ff);
    line-height: 23px;
    background: #ffffff47;
    border: 1px solid var(--ff);
    padding: 10px;
    border-radius: 10px;
}

#show_select .show_select .Package_full_ul ul li::before {
    content: '\2022';
    color: var(--bg);
    display: inline-block;
    width: 10px;
}

#show_select .show_select .Package_full_ul h4 {
    width: fit-content;
    color: var(--ff);
    padding: 5px 10px;
    border-radius: 30px;
    font-size: 20px;
}

#show_select svg.IoCloseSharp {
    position: fixed;
    top: 10px;
    right: 10px;
    font-size: 35px;
    color: var(--ff);
    cursor: pointer;
}

#show_select svg.IoCloseSharp:hover {
    opacity: 0.7;
}

#show_select .center {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 5px;
}

#show_select .center label {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

#show_select .center input {
    width: 20px;
    border-radius: 0;
    border: none;
    appearance: auto;
    height: 20px;
    font-size: 0;
    font-weight: 100;
    line-height: 0;
    letter-spacing: 0em;
    text-align: center;
    cursor: pointer;
}

#show_select p.handleHideTerms {
    font-size: 25px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    color: var(--ff);
    cursor: pointer;
}


#show_select .terms_book_now {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    background: var(--bg);
    color: var(--ff);
    width: 124.1px;
    height: 51.1px;
    border-radius: 5rem;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid var(--ff);
}


@media (max-width: 1100px) {
    #show_select .show_select {
        gap: 17px;
        padding: 29.75px 0;
    }

    #show_select .show_select h2 {
        font-size: 34px;
        line-height: 34px;
        margin: 14.45px 0;
    }

    .selected_data_all {
        gap: 8.5px;
    }

    .selected_data_all .selected_data b {
        font-size: 21.25px;
    }

    .selected_data_all .selected_data p {
        font-size: 59.5px;
        line-height: 46.75px;
        margin-top: 8.5px;
    }

    .selected_data_all .selected_data p span {
        font-size: 17px;
        line-height: 17px;
    }

    #show_select .show_select .Package_full_ul {
        max-width: 425px;
    }

    #show_select .show_select .Package_full_ul ul {
        font-size: 15px;
        line-height: 20px;
    }

    #show_select .show_select .Package_full_ul h4 {
        padding: 4.25px 8.5px;
        font-size: 17px;
    }

    #show_select svg.IoCloseSharp {
        font-size: 29.75px;
    }

    p.handleHideTerms {
        font-size: 20px;
        line-height: 25px;
    }
}

@media (max-width: 900px) {
    #show_select .show_select {
        gap: 14.45px;
        padding: 25.2875px 0;
    }

    #show_select .show_select h2 {
        font-size: 28.9px;
        line-height: 28.9px;
        margin: 12.27825px 0;
    }

    .selected_data_all {
        gap: 7.225px;
    }

    .selected_data_all .selected_data b {
        font-size: 18.0625px;
    }

    .selected_data_all .selected_data p {
        font-size: 50.575px;
        line-height: 39.7375px;
        margin-top: 7.225px;
    }

    .selected_data_all .selected_data p span {
        font-size: 14.45px;
        line-height: 14.45px;
    }

    #show_select .show_select .Package_full_ul {
        max-width: 361.25px;
    }

    #show_select .show_select .Package_full_ul ul {
        font-size: 14px;
        line-height: 18px;
    }

    #show_select .show_select .Package_full_ul h4 {
        padding: 3.6125px 7.225px;
        font-size: 14.45px;
    }

    #show_select svg.IoCloseSharp {
        font-size: 25.2875px;
    }

    p.handleHideTerms {
        font-size: 18px;
        line-height: 22.5px;
    }
}

@media (max-width: 750px) {
    #show_select .show_select {
        gap: 12.2825px;
        padding: 21.494375px 0;
    }

    #show_select .show_select h2 {
        font-size: 24.565px;
        line-height: 24.565px;
        margin: 10.4365125px 0;
    }

    .selected_data_all {
        gap: 6.14125px;
    }

    .selected_data_all .selected_data b {
        font-size: 15.3528125px;
    }

    .selected_data_all .selected_data p {
        font-size: 42.98975px;
        line-height: 33.776875px;
        margin-top: 6.14125px;
    }

    .selected_data_all .selected_data p span {
        font-size: 12.2825px;
        line-height: 12.2825px;
    }

    #show_select .show_select .Package_full_ul {
        max-width: 306.0625px;
    }

    #show_select .show_select .Package_full_ul ul {
        font-size: 13px;
        line-height: 16px;
    }

    #show_select .show_select .Package_full_ul h4 {
        padding: 3.070625px 6.14125px;
        font-size: 12.2825px;
    }

    #show_select svg.IoCloseSharp {
        font-size: 21.494375px;
    }

    p.handleHideTerms {
        font-size: 16.2px;
        line-height: 20.25px;
    }
}


@media (max-width: 600px) {
    #show_select .show_select {
        gap: 10.440125px;
        padding: 18.27021875px 0;
    }

    #show_select .show_select h2 {
        font-size: 20.88025px;
        line-height: 20.88025px;
        margin: 8.871035625px 0;
    }

    .selected_data_all {
        gap: 5.2200625px;
    }

    .selected_data_all .selected_data b {
        font-size: 13.050890625px;
    }

    .selected_data_all .selected_data p {
        font-size: 36.5412875px;
        line-height: 28.71034375px;
        margin-top: 5.2200625px;
    }

    .selected_data_all .selected_data p span {
        font-size: 10.440125px;
        line-height: 10.440125px;
    }

    #show_select .show_select .Package_full_ul {
        max-width: 260.153125px;
    }

    #show_select .show_select .Package_full_ul ul {
        font-size: 11.05px;
        line-height: 13.6px;
    }

    #show_select .show_select .Package_full_ul h4 {
        padding: 2.60953125px 5.2190625px;
        font-size: 10.440125px;
    }

    #show_select svg.IoCloseSharp {
        font-size: 18.27021875px;
    }

    p.handleHideTerms {
        font-size: 14.58px;
        line-height: 18.225px;
    }
}

@media (max-width: 430px) {
    #show_select .show_select {
        gap: 8.8741063125px;
        padding: 15.5296859375px 0;
    }

    #show_select .show_select h2 {
        font-size: 17.74821125px;
        line-height: 17.74821125px;
        margin: 7.54038028125px 0;
    }

    .selected_data_all {
        gap: 4.4370530625px;
    }

    .selected_data_all .selected_data b {
        font-size: 11.09325703125px;
    }

    .selected_data_all .selected_data p {
        font-size: 31.059094375px;
        line-height: 24.4037921875px;
        margin-top: 4.4370530625px;
    }

    .selected_data_all .selected_data p span {
        font-size: 8.8741063125px;
        line-height: 8.8741063125px;
    }

    #show_select .show_select .Package_full_ul {
        max-width: 221.13015625px;
    }

    #show_select .show_select .Package_full_ul ul {
        font-size: 9.39225px;
        line-height: 11.56px;
    }

    #show_select .show_select .Package_full_ul h4 {
        padding: 2.2171015625px 4.434203125px;
        font-size: 8.8741063125px;
    }

    #show_select svg.IoCloseSharp {
        font-size: 15.5306859375px;
    }
}

@media (min-width: 320px) and (max-width: 400px) {
    #show_select .show_select {
        gap: 7.542990365625px;
        padding: 13.200232046875px 0;
    }

    #show_select .show_select h2 {
        font-size: 15.0869795625px;
        line-height: 15.0869795625px;
        margin: 6.4093232380625px 0;
    }

    .selected_data_all {
        gap: 3.7714951030625px;
    }

    .selected_data_all .selected_data b {
        font-size: 9.4292689765625px;
    }

    .selected_data_all .selected_data p {
        font-size: 26.40023021875px;
        line-height: 20.743223953125px;
        margin-top: 3.7714951030625px;
    }

    .selected_data_all .selected_data p span {
        font-size: 7.542990365625px;
        line-height: 7.542990365625px;
    }

    #show_select .show_select .Package_full_ul {
        max-width: 187.9606328125px;
    }

    #show_select .show_select .Package_full_ul ul {
        font-size: 7.98341125px;
        line-height: 9.826px;
    }

    #show_select .show_select .Package_full_ul h4 {
        padding: 1.884635828125px 3.76927165625px;
        font-size: 7.542990365625px;
    }

    #show_select svg.IoCloseSharp {
        font-size: 13.200232046875px;
    }
}