#header {
    width: 100%;
    height: 720px;
    background: url("../../../img/header.webp") no-repeat;
    background-size: cover;
    background-position: center;
    padding: 0 41px;
    overflow-x: hidden;
}

#header .header {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

#header .header h3 {
    font-size: 30px;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: 0em;
    color: var(--bg_2);
    text-transform: uppercase;
    position: relative;
    z-index: 1;
}

#header .header h2 {
    font-size: 90px;
    font-weight: 700;
    line-height: 108.92px;
    letter-spacing: 0em;
    color: var(--bg_2);
    text-transform: uppercase;
    position: relative;
    z-index: 1;
}

#header .header h2.with {
    font-size: 37px;
    line-height: 44.78px;
}

#header .header h2.with span {
    color: var(--bg);
    font-size: 30px;
}

#header .header .div_header {
    display: flex;
    flex-direction: column;
}

#header .header a.book_now {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    background: var(--bg);
    color: var(--ff);
    width: 124.1px;
    height: 51.1px;
    border-radius: 5rem;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    z-index: 1;
}

#header .header a.book_now:hover {
    opacity: 0.7;
}

#header .header h2:nth-child(4) {
    color: var(--bg);
}

@media (max-width: 1100px) {
    #header {
        height: 620px;
        padding: 0 35px;
    }

    #header .header h3 {
        font-size: 26px;
        line-height: 38px;
    }

    #header .header h2 {
        font-size: 70px;
        line-height: 70px;
    }

    #header .header h2.with {
        font-size: 30px;
        line-height: 30.78px;
    }

    #header .header a.book_now {
        font-size: 14px;
        line-height: 15px;
        width: 100px;
        height: 43px;
        margin-top: 15px;
    }

    #header .header h2.with span {
        font-size: 25px;
    }
}

@media (max-width: 900px) {
    #header {
        height: 550px;
        padding: 0 35px;
    }

    #header .header h3 {
        font-size: 20px;
        line-height: 30px;
    }

    #header .header h2 {
        font-size: 55px;
        line-height: 60px;
    }

    #header .header h2.with {
        font-size: 25px;
        line-height: 25px;
    }

    #header .header a.book_now {
        font-size: 12px;
        line-height: 12px;
        width: 90px;
        height: 38px;
        margin-top: 10px;
    }

    #header .header h2.with span {
        font-size: 20px;
    }
}

@media (max-width: 750px) {
    #header {
        height: 500px;
    }

    #header .header h3 {
        font-size: 14px;
        line-height: 23px;
    }

    #header .header h2 {
        font-size: 40px;
        line-height: 50px;
    }

    #header .header h2.with {
        font-size: 19px;
        line-height: 20px;
    }

    #header .header a.book_now {
        width: 70px;
        height: 25px;
    }

    #header .header h2.with span {
        font-size: 16px;
    }
}


@media (max-width: 600px) {
    #header {
        height: 400px;
    }

    #header .header h3 {
        font-size: 13px;
        line-height: 20px;
    }

    #header .header h2 {
        font-size: 35px;
        line-height: 40px;
    }

    #header .header h2.with {
        font-size: 15px;
        line-height: 15px;
    }

    #header .header a.book_now {
        font-size: 10px;
    }

    #header .header h2.with span {
        font-size: 12px;
    }
}

@media (max-width: 430px) {


    #header {
        height: 953px;
        padding: 0 30px;
        background-position: 90%;
        position: relative;
    }

    #header .header {
        align-items: center;
    }

    #header .header .div_header::after {
        position: absolute;
        content: "";
        width: 370px;
        height: 370px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 5px;
        background: #ffffffc7;
    }

    #header .header h3 {
        font-size: 23px;
        width: 132px;
        line-height: 27.84px;
    }

    #header .header h2 {
        font-size: 70px;
        line-height: 85px;
    }

    #header .header h2.with {
        font-size: 28px;
        line-height: 33.89px;
        text-align: center;
    }


    #header .header h2.with span {
        display: flex;
        font-size: 15px;
    }

    #header .header a.book_now {
        width: 103.9px;
        height: 42.78px;
        font-size: 13px;
        line-height: 15.73px;
    }

    #header .header .div_header {
        position: relative;
        top: -30px;
        align-items: center;
        justify-content: center;
    }
}

@media (min-width: 320px) and (max-width: 400px) {
    #header .header .div_header::after {
        width: 320px;
        height: 320px;
    }

    #header .header .div_header {
        top: -75px;
    }

    #header .header a.book_now {
        width: 90px;
        height: 35px;
        font-size: 13px;
        line-height: 15.73px;
    }

    #header .header h2.with {
        font-size: 25px;
        line-height: 30px;
        text-align: center;
    }

    #header .header h2 {
        font-size: 55px;
        line-height: 75px;
    }

    #header .header h3 {
        font-size: 20px;
        width: 120px;
        line-height: 24px;
    }
}


/* direction_rtl */
.direction_rtl #header {
    transform: rotateY(180deg);
}
.direction_rtl #header .header {
    transform: rotateY(180deg);
}