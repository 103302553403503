#planTripNow {
    width: 100%;
    height: 720px;
    padding: 0 41px;
    overflow-x: hidden;
}

#planTripNow .planTripNow {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
}


#planTripNow .planTripNow .div_quick_easy {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
}

.div_quick_easy .quick_easy {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 340px;
    text-align: center;
}

.div_quick_easy .quick_easy .img_quick_easy {
    width: 80px;
    line-height: 0;
}

.div_quick_easy .quick_easy h2 {
    font-size: 23px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    color: var(--bg_2);
    margin-top: 20px;
    margin-bottom: 20px;
    text-transform: uppercase;
    position: relative;
    transform: translateY(-50%);
}


.div_quick_easy .quick_easy p {
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    color: var(--bg_2);
}


#planTripNow .title {
    width: 300px;
}

.div_quick_easy .quick_easy h2 img.quick_easy_arrow_computer {
    display: block;
    position: absolute;
    width: 100px;
    top: 50%;
    transform: translateY(-50%);
}


img.quick_easy_arrow_computer.left {
    left: -120px;
}

img.quick_easy_arrow_computer.right {
    right: -120px;
}

.div_quick_easy img.quick_easy_arrow_phone {
    display: none;
}


@media (max-width: 1260px) {
    .div_quick_easy .quick_easy h2 img.quick_easy_arrow_computer {
        display: none;
    }


}

@media (max-width: 1100px) {
    #planTripNow {
        height: 620px;
        padding: 0 35px;
    }

    #planTripNow .title {
        width: 260px;
    }

    .title_div p {
        font-size: 18px;
        line-height: 22px;
    }

    .title_div h2 {
        font-size: 30px;
        line-height: 35px;
    }

    .div_quick_easy .quick_easy {
        width: 275px;
    }

    .div_quick_easy .quick_easy h2 {
        font-size: 18px;
        line-height: 18px;
        margin-top: 18px;
        margin-bottom: 18px;
    }

    .div_quick_easy .quick_easy p {
        font-size: 16px;
        line-height: 20px;
    }
}

@media (max-width: 900px) {
    #planTripNow {
        height: 558px;
        padding: 0 31.5px;
    }

    #planTripNow .title {
        width: 234px;
    }

    .title_div p {
        font-size: 16.2px;
        line-height: 19.8px;
    }

    .title_div h2 {
        font-size: 27px;
        line-height: 31.5px;
    }

    .div_quick_easy .quick_easy {
        width: 220px;
    }

    .div_quick_easy .quick_easy h2 {
        font-size: 13.2px;
        line-height: 10.2px;
        margin-top: 12.2px;
        margin-bottom: 10.2px;
    }

    .div_quick_easy .quick_easy p {
        font-size: 12.4px;
        line-height: 15px;
    }

    .save_with .right .quick_easy_5 {
        width: 75px;
    }

    .save_with .right .save_h3 h3 {
        font-size: 25px;
        line-height: 35px;
    }

    .save_with .right .save_h3 h3 span {
        font-size: 40px;
        line-height: 30px;
    }

    .save_with .right .top_airports a.book_now {
        font-size: 13px;
        line-height: 15px;
        width: 100px;
        height: 45px;
        margin-top: 8px;
    }
}

@media (max-width: 750px) {
    #planTripNow {
        height: 502.2px;
        padding: 0 28.35px;
    }

    #planTripNow .title {
        width: 210.6px;
    }

    .title_div p {
        font-size: 14.58px;
        line-height: 17.82px;
    }

    .title_div h2 {
        font-size: 24.3px;
        line-height: 28.35px;
    }

    .div_quick_easy .quick_easy {
        width: 175px;
    }

    .div_quick_easy .quick_easy h2 {
        font-size: 12.58px;
        line-height: 10.58px;
        margin-top: 10.58px;
        margin-bottom: 10.58px;
    }

    .div_quick_easy .quick_easy p {
        font-size: 10px;
        line-height: 14.2px;
    }


    .save_with .right .quick_easy_5 {
        width: 70px;
    }

    .save_with .right .save_h3 h3 {
        font-size: 22px;
        line-height: 30px;
    }

    .save_with .right .save_h3 h3 span {
        font-size: 35px;
        line-height: 25px;
    }

    .save_with .right .top_airports a.book_now {
        font-size: 12px;
        line-height: 13px;
        width: 90px;
        height: 40px;
        margin-top: 7px;
    }
}


@media (max-width: 600px) {
    #planTripNow {
        height: 452.97px;
        padding: 0 25.515px;
    }

    #planTripNow .title {
        width: 189.54px;
    }

    .title_div p {
        font-size: 13.122px;
        line-height: 16.038px;
    }

    .title_div h2 {
        font-size: 21.87px;
        line-height: 25.515px;
    }

    .div_quick_easy .quick_easy {
        width: 110px;
    }

    .div_quick_easy .quick_easy h2 {
        font-size: 12px;
        line-height: 13px;
        margin-top: 20px;
        margin-bottom: 0;
    }

    .div_quick_easy .quick_easy p {
        font-size: 10px;
        line-height: 12px;
    }

    .save_with .right .quick_easy_5 {
        width: 60px;
    }

    .save_with .right .save_h3 h3 {
        font-size: 20px;
        line-height: 25px;
    }

    .save_with .right .save_h3 h3 span {
        font-size: 30px;
        line-height: 20px;
    }

    .save_with .right .top_airports a.book_now {
        font-size: 11px;
        line-height: 12px;
        width: 80px;
        height: 35px;
    }
}


@media (max-width: 430px) {
    #planTripNow {
        height: 1284px;
        padding: 0 30px;
    }

    .save_with {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        height: 936px;
    }

    .div_quick_easy .quick_easy {
        width: 340px;
    }

    #planTripNow .planTripNow .div_quick_easy {
        justify-content: center;
    }

    .div_quick_easy .quick_easy h2 img {
        display: none;
    }

    .div_quick_easy .quick_easy.arrow {
        margin: 171px 0;
    }

    .div_quick_easy img.quick_easy_arrow_phone {
        display: block;
        width: 151px;
        transform: rotate(90deg);
        position: relative;
    }

    .div_quick_easy img.quick_easy_arrow_phone.left {
        top: 85px;
    }

    .div_quick_easy img.quick_easy_arrow_phone.right {
        top: -85px;
    }

    .div_quick_easy .quick_easy h2 {
        margin-top: 24px;
        margin-bottom: 0;
        transform: none;
        font-size: 18px;
        line-height: 18px;
    }

    .div_quick_easy .quick_easy p {
        font-size: 18px;
        line-height: 21.78px;
    }

    .save_with .left img {
        object-fit: cover;
    }

    .save_with .left {
        position: relative;
        z-index: -2;
    }

    .save_with .span {
        top: -50px;
        right: 50%;
        transform: translate(50%, 0) rotate(90deg);
        z-index: -1;
    }

    .save_with .right {
        justify-content: center;
        padding-top: 0;
        padding-bottom: 0;
        gap: 26px;
    }

    .save_with .right .quick_easy_5 {
        width: 97px;
    }

    .save_with .right .save_h3 h3 {
        font-size: 30px;
        line-height: 36px;
    }

    .save_with .right .top_airports a.book_now {
        font-size: 16px;
        line-height: 19px;
        width: 124.37px;
        height: 51.21px;
        margin-top: 8px;
    }
}

@media (min-width: 320px) and (max-width: 400px) {
    #planTripNow {
        height: 1091.4px;
        padding: 0 25.5px;
    }

    .save_with {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        height: 795.6px;
    }

    #planTripNow .planTripNow .div_quick_easy {
        justify-content: center;
    }

    .div_quick_easy .quick_easy h2 img {
        display: none;
    }

    .div_quick_easy .quick_easy.arrow {
        margin: 145.35px 0;
    }

    .div_quick_easy img.quick_easy_arrow_phone {
        display: block;
        width: 128.35px;
        transform: rotate(90deg);
        position: relative;
    }

    .div_quick_easy img.quick_easy_arrow_phone.left {
        top: 72.25px;
    }

    .div_quick_easy img.quick_easy_arrow_phone.right {
        top: -72.25px;
    }

    .div_quick_easy .quick_easy h2 {
        margin-top: 20.4px;
        margin-bottom: 0;
        transform: none;
    }

    .div_quick_easy .quick_easy p {
        font-size: 15.3px;
        line-height: 18.51px;
    }

    .save_with .left img {
        object-fit: cover;
    }

    .save_with .left {
        position: relative;
        z-index: -2;
    }

    .save_with .span {
        top: -42.5px;
        right: 50%;
        transform: translate(50%, 0) rotate(90deg);
        z-index: -1;
    }

    .save_with .right {
        justify-content: center;
        padding-top: 0;
        padding-bottom: 0;
        gap: 22.1px;
    }
}